import '@client/common/services/init';
import '@client/common/services/init-sentry';
import initHbi from '@client/common/services/init-performance-report';
import { getLang } from '@nimo-fed/x';
var lang = getLang();
var isRtl = document.dir === 'rtl';
if (lang === '1025') {
  if (!isRtl) document.dir = 'rtl';
} else if (isRtl) {
  document.dir = '';
}
initHbi();